import { TCommonProps } from "./common";
import {
	collisionDetectionLowGravity,
	collisionDetectionNoGravity,
	collisionDetectionNormalGravity,
	editingSettings,
	orbitalMotion,
	pathfindingContinuous,
	pathfindingMixedChangingScreen,
	pathfindingObstacles,
	shapeGeneration,
	sinusoidalMotion,
  } from "../assets";

type TTechnical = {
	title: string;
	description: string[];
}

type TFeature = {
	title: string;
	description: string[];
	videos: string[];
}

export type TProject = {
	description: string[];
	technicals: TTechnical;
	features: TFeature[];
  } & Required<Pick<TCommonProps, "name">>;

export const projects: TProject[] = [
	{
	  name: "Simulation engine",
	  description: [
		"As a C# coding challenge I handcrafted a simplified 2D physics engine for simulating environmental forces on shape objects.",
		"All modules were built by myself from the ground up, without utilising any third party library or existing codebase.",
		"Purpose was to create a solution that I could 100% own and have greater autonomy for making improvements."
	  ],
	  technicals: {
		title: "Technicals",
		description: [
			"Multi-threaded Win32 application to separate background and foreground processing",
			"Real-time graphics rendering using the GDI+ Win32 API",
			"Event-driven simulation editor to deliver WYSIWYG experience",
			"JSON-backed settings profile configuration supporting all CRUD operations"
	  ]},
	  features: [
		{
			title: "Collision Detection",
			description: [
				"Collision Detection of concave and convex shapes with translational and rotational transformations",
				"High precision collision detection where collision boundaries follow the outline of the shapes",
				"The physics for calculating the collision response is simplified to include things like direction and momentum, using mass and velocity"
			],
			videos: [collisionDetectionNoGravity, collisionDetectionLowGravity, collisionDetectionNormalGravity]
		},
		{
			title: "Environmental forces",
			description: [
				"Simulating various environmental forces using vector fields for non-linear motion e.g. orbital or sinusoidal motion",
				"Event-based motion, e.g. the forces can be configured to alternate direction on a timed interval"
			],
			videos: [sinusoidalMotion, orbitalMotion]
		},
		{
			title: "Pathfinding",
			description: [
				"Shape pathfinding around static and moving obstacles, using different strategies based on the A* algorithm",
				"Usage of non-linear functions to generate non-linear path waypoints e.g. sinusoidal paths"
			],
			videos: [pathfindingContinuous, pathfindingMixedChangingScreen, pathfindingObstacles]
		},
		{
			title: "Supporting",
			description: [
				"Highly configurable simulations using separate settings profiles",
				"Shape and scene generation using parameterised criteria",
				"Verbose logging and monitoring for troubleshooting"
			],
			videos: [shapeGeneration, editingSettings]
		}
	  ]
	}
  ];
