import { motion } from "framer-motion";

import { SectionHeader, SectionWrapper } from "./shared";
import { config, testimonials, TTestimonial } from "../../models";
import { styles } from "../../styles";
import { fadeIn } from "../../utils/motion";


const TestimonialCard: React.FC<{ index: number } & TTestimonial> = ({
  index,
  testimonial,
  name,
  designation,
  company
}) => (
  <motion.div
    variants={fadeIn("", "spring", index * 0.5, 0.75)}
    className="bg-black-200 xs:w-[320px] w-full rounded-3xl p-10"
  >
    <p className="text-[48px] font-black text-white">"</p>

    <div className="mt-1">
	{testimonial.map((point: string, index: number) => (
      <p key={index} className={`text-[18px] tracking-wider text-white italic ${styles.p}`}>{point}</p>
	))}

      <div className="mt-7 flex items-center justify-between gap-1">
        <div className="flex flex-1 flex-col">
          <p className="text-[16px] font-medium text-white">
            {name}
          </p>
          <p className="text-secondary mt-1 text-[12px]">
            {designation}
          </p>
		  <p className="text-secondary mt-1 text-[12px]">
            {company}
          </p>
        </div>
      </div>
    </div>
  </motion.div>
);

const Testimonials = () => {
  return (
    <div className="bg-black-100 mt-12 rounded-[20px]">
      <div
        className={`${styles.padding} bg-tertiary min-h-[300px] rounded-2xl`}
      >
        <SectionHeader useMotion={true} {...config.sections.testimonials} />
      </div>
      <div
        className={`${styles.paddingX} -mt-20 flex flex-wrap gap-7 pb-14 max-sm:justify-center`}
      >
        {testimonials.map((testimonial, index) => (
          <TestimonialCard key={testimonial.name} index={index} {...testimonial} />
        ))}
      </div>
    </div>
  );
};

export default SectionWrapper(Testimonials, "testimonials");
