import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { avatar, menu, close } from "../../assets";
import { config, navLinks } from "../../models";
import { styles } from "../../styles";

const NavBar = () => {
  const [toggle, setToggle] = useState(false);
  const [active, setActive] = useState("");

  useEffect(() => {
	document.title = config.html.title;
	
	// Used for implementing a ScrollSpy equivalent, where the navigation active state depends on the
	// scroll position, and whether the section is determined to be visible
	const observer = new IntersectionObserver(
		(entries) => {
		  entries.forEach((entry) => {
			if (entry.isIntersecting)
				setActive(entry.target.id);
		  });
		},
		{
			// document is the viewport
		  	root: document,
			// A section is deemed active if the content is visible 80% above the top of the viewport and 20%
			// above the bottom of the viewport. A higher number seems more accurate when scrolling up through
			// the sections
			//
			// A lower number for the bottom is to cater for the Contact section, which is a much smaller section,
			// when scrolling down through the sections
		  	rootMargin: "-80% 0px -20% 0px"
		}
	  );
	
	  const sections = document.querySelectorAll('section');
	
	  sections.forEach((section) => {
		observer.observe(section);
	  });
  }, [])


  return (
    <nav
      className={`${styles.paddingX} fixed top-0 z-20 flex w-full items-center py-5 bg-primary`}
    >
      <div className="mx-auto flex w-full max-w-7xl items-center justify-between">
		{/* avatar and title */}
        <Link
          to="/"
          className="flex items-center gap-2"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <img src={avatar} alt="avatar" className="h-9 w-9 object-contain" />
          <p className="flex cursor-pointer text-[16px] xs:text-[18px] font-bold text-white">
            {config.html.title}
          </p>
        </Link>

		{/* expanded navLinks */}
        <ul className="hidden list-none flex-row gap-10 xl:flex">
          {navLinks.map((nav) => (
            <li
              key={nav.id}
              className={`${
                active === nav.id ? "text-white" : "text-secondary"
              } cursor-pointer text-[18px] font-medium hover:text-white`}
            >
              <a href={`#${nav.id}`}>{nav.title}</a>
            </li>
          ))}
        </ul>

		{/* collapsed navLinks */}
        <div className="flex flex-1 items-center justify-end xl:hidden">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="h-[28px] w-[28px] object-contain"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } black-gradient absolute right-0 top-20 z-10 mx-4 my-2 min-w-[140px] rounded-xl p-6`}
          >
            <ul className="flex flex-1 list-none flex-col items-start justify-end gap-4">
              {navLinks.map((nav) => (
                <li
                  key={nav.id}
                  className={`font-poppins cursor-pointer text-[16px] font-medium ${
                    active === nav.id ? "text-white" : "text-secondary"
                  }`}
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                >
                  <a href={`#${nav.id}`}>{nav.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
