import { motion } from "framer-motion";

import { SectionHeader, SectionWrapper } from "./shared";
import { config, values } from "../../models";
import { styles } from "../../styles";
import { fadeIn } from "../../utils/motion";

const About = () => {
  return (
    <>
      <SectionHeader useMotion={true} {...config.sections.about} />

      <motion.div
        variants={fadeIn("", "", 0.1, 1)}
        className="text-secondary mt-4 max-w-3xl text-[17px] leading-[30px]"
      >
        {config.sections.about.content.map((item: string, index: number) => (
          <p key={`about-${index}`} className={styles.p}>
            {item}
          </p>
        ))}

        <h3 className={styles.h3}>{values[0].name}</h3>

        <ul className="ml-5 mt-5 list-disc space-y-2">
          {values[0].content.map((point: string, index: number) => (
            <li
              key={`tenet-point-${index}`}
              className="text-secondary pl-1 text-[17px] tracking-wider"
            >
              {point}
            </li>
          ))}
        </ul>
      </motion.div>
    </>
  );
};

export default SectionWrapper(About, "about");
