import "./Contact.scss";
import { config } from "../../models";

const Contact = () => {
  return (
    <section id="contact" className="pt-10">
      <footer>
        <svg viewBox="0 0 120 7">
          <defs>
            <filter id="goo">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="2"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="
				1 0 0 0 0  
				0 1 0 0 0  
				0 0 1 0 0  
				0 0 0 13 -9"
                result="goo"
              />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
            <path
              id="wave"
              d="M 0,2 C 30,2 30,3 60,3 90,3 90,2 120,2 150,2 150,3 180,3 210,3 210,2 240,2 v 7 h -240 z"
            />
          </defs>

          <use id="wave3" className="wave" xlinkHref="#wave" x="0" y="-2"></use>
          <use id="wave2" className="wave" xlinkHref="#wave" x="0" y="0"></use>

          <g className="gooeff">
            <use id="wave1" className="wave" xlinkHref="#wave" x="0" y="1" />
          </g>
        </svg>

        <div className="bg-[#563D9C] text-center pt-8 md:pt-0 pb-10">
          <div className="flex justify-center gap-8 pb-5">
            <a href={`mailto:${config.html.email}`} aria-label="Send email" className="pt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40.21"
                height="28"
                viewBox="0 0 122.88 85.57"
              >
                <path d="M3.8,0,62.48,47.85,118.65,0ZM0,80.52,41.8,38.61,0,4.53v76ZM46.41,42.37,3.31,85.57h115.9L78,42.37,64.44,53.94h0a3,3,0,0,1-3.78.05L46.41,42.37Zm36.12-3.84,40.35,42.33V4.16L82.53,38.53Z"></path>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/in/james-roome-4a58b2110"
              aria-label="View LinkedIn profile"
			  target="_blank"
			  rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 24 24"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11.421 20h-3.579v-11h3.579v11zm-1.79-12.436c-1.146 0-1.876-.811-1.876-1.771 0-1.002.757-1.773 1.931-1.773s1.876.771 1.897 1.773c0 1.021-.731 1.771-1.913 1.771h-.039zm13.421 12.436h-3.579v-5.801c0-1.38-.494-2.323-1.726-2.323-.941 0-1.501.633-1.748 1.244-.09.216-.113.518-.113.821v6.059h-3.579v-11h3.579v1.506c.472-.735 1.312-1.792 3.18-1.792 2.318 0 4.057 1.508 4.057 4.754v6.532z" />
              </svg>
            </a>
          </div>
          <div className="text-secondary pl-1 text-[17px] tracking-wider">
            {config.html.fullName} © {config.html.copyrightYear}
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Contact;
