import { TCommonProps } from "./common";

export type TTestimonial = {
	testimonial: string[];
	designation: string;
	company: string;
  } & Required<Pick<TCommonProps, "name">>;

export const testimonials: TTestimonial[] = [
	{
		testimonial: [
			"James has been the ultimate team player over the last few months, fielding many hats in many areas. His input into every area of the business across all departments has been helpful to all - Support, Tech Services, Development and ofcourse his own Dept PSD.",
			"He has been stretched to the limit by being short staffed yet has picked up the leftover work from Tim, trained four people, eager to step into a development role and still on hand to help always."
		],
		name: "Roger Gibson",
		designation: "Professional Services Manager",
		company: "Markinson Business Solutions"
	},
	{
		testimonial: [
			"James showed outstanding commitment to the completion of an urgent development project for Australian Clutch Services.",
			"James worked well outside of normal hours and produced an excellent result for the client in a very short timeframe.",
			"Well done James!"
		],
		name: "Ben Welch",
		designation: "Development Team Leader",
		company: "Markinson Business Solutions"
	},
	{
		testimonial: [
			"Thank you for all your efforts in making Markinson what it is"
		],
		name: "Ian Whiting",
		designation: "CEO",
		company: "Markinson Business Solutions"
	  },
  ];