import { BrowserRouter } from "react-router-dom";

import NavBar from "./NavBar";
import { About, Contact, Experience, Hero, Projects, Skills, Testimonials } from "../sections";

const App = () => {
  return (
    <BrowserRouter>
      <div className="bg-primary relative z-0">
		<NavBar />
		<div className="bg-hero-pattern bg-cover bg-center bg-no-repeat">
			<Hero />
			<About />
		</div>
		<Skills />
        <Experience />
		<Projects />
		<Testimonials />
		<Contact />
      </div>
    </BrowserRouter>
  );
};

export default App;
