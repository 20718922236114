import React from "react";
import Tilt from "react-parallax-tilt";
import { motion } from "framer-motion";

import { SectionHeader, SectionWrapper } from "./shared";
import { config, skills } from "../../models";
import { fadeIn } from "../../utils/motion";
import { styles } from "../../styles";

interface ICard {
  children: React.ReactNode;
  index: number;
}

interface IExpertiseCard {
  index: number;
  title: string;
  icon: string;
}

interface ISkillCard {
  index: number;
  title: string;
  items: string[];
}

// On mobile devices we use an expandable width for cards so card can occupy full width.
// On all other screens, we use a fixed width so cards can be centered as screen grows
const Card: React.FC<ICard> = ({ children, index }) => (
  <Tilt
    glareEnable
    tiltEnable
    tiltMaxAngleX={30}
    tiltMaxAngleY={30}
    glareColor="#aaa6c3"
    className="flex-auto w-full xs:flex-initial xs:w-[250px]"
  >
    <div className="h-full">
      <motion.div
        variants={fadeIn("right", "spring", index * 0.5, 0.75)}
        className="green-pink-gradient shadow-card w-full h-full rounded-[20px] p-[1px]"
      >
        <div className="bg-tertiary min-h-[280px] h-full rounded-[20px] px-8 py-8">
          {children}
        </div>
      </motion.div>
    </div>
  </Tilt>
);

const ExpertiseCard: React.FC<IExpertiseCard> = ({ index, title, icon }) => (
  <Card index={index}>
    <div className="flex flex-col items-center justify-around h-full">
      <img
        src={icon}
        alt="web-development"
        className="h-24 w-24 object-contain"
      />

      <h3 className="text-center text-[20px] font-bold text-white">{title}</h3>
    </div>
  </Card>
);

const SkillCard: React.FC<ISkillCard> = ({ index, title, items }) => (
  <Card index={index}>
    <>
      <h3 className="text-center text-[20px] font-bold text-white">
        {title}
      </h3>

      <ul className="ml-5 mt-5 list-disc space-y-2">
        {items.map((item: string, index: number) => (
          <li
            key={`skill-${index}`}
            className="text-secondary pl-1 text-[17px] tracking-wider"
          >
            {item}
          </li>
        ))}
      </ul>
    </>
  </Card>
);

const Expertise: React.FC = () => (
  <>
    <h3 className={`mt-10 ${styles.h3}`}>{skills.expertise.title}</h3>
    <div className="mt-10 mb-10 flex flex-wrap gap-10 justify-center">
      {skills.expertise.items.map((expertise, index) => (
        <ExpertiseCard
          key={`expertise-${index}`}
          index={index}
          {...expertise}
        />
      ))}
    </div>
    {skills.expertise.description.map((point: string, index: number) => (
      <p
        key={`expertise-desc-${index}`}
        className={`text-secondary mt-3 text-[17px] leading-[30px]`}
      >
        {point}
      </p>
    ))}
    <ul className="ml-5 mt-5 list-disc space-y-2">
      {skills.expertise.additional.map((point: string, index: number) => (
        <li
          key={`expertise-additional-${index}`}
          className="text-secondary pl-1 text-[17px] tracking-wider"
        >
          {point}
        </li>
      ))}
    </ul>
  </>
);

const Technicals: React.FC = () => (
  <>
    <h3 className={`mt-10 ${styles.h3}`}>{skills.technicals.title}</h3>
    <div className="mt-10 flex flex-wrap gap-10 justify-center">
      {skills.technicals.items.map((skill, index) => (
        <SkillCard key={`technical-${index}`} index={index} {...skill} />
      ))}
    </div>
  </>
);

const Skills = () => {
  return (
    <>
      <SectionHeader useMotion={true} {...config.sections.skills} />
      <Expertise />
      <Technicals />
    </>
  );
};

export default SectionWrapper(Skills, "skills");
