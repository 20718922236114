import { TCommonProps } from "./common";

export type TValue = {
	content: string[];
  } & Required<Pick<TCommonProps, "name">>;

export const values: TValue[] = [
{
	name: "Values",
	content: [
		"Passionate about using technology as a powerful tool to make a difference by solving real-world business challenges",
		"Taking pride and accountability in the work that I deliver, to produce high quality output",
		"Having a strong work ethic, necessary to see a project through to its successful completion",
		"Investing the time to fully understand requirements, leading to a robust design and leaving no surprises at the end",
		"Documenting important findings, as you will be thankful when revisiting after an extended period",
		"Going above and beyond, by ascertaining how the system can be improved to prevent bugs in future",
		"Big picture thinker, by considering how a task can benefit the rest of the product or customer base",
		"Following the Boy scout principle, leave your code better than you found it",
		"Starting with the customer experience first, then working backward to the technology",
		"Learning and understanding the domain, is just as interesting as the technology",
		"You can never know it all, there is always something new to learn"
	]
}
];