export const styles = {
	paddingX: "sm:px-16 px-6",
	paddingY: "sm:py-16 py-6",
	padding: "sm:px-16 px-6 sm:py-16 py-10",
  
	heroHeadText:
	  "font-black text-[#915EFF] lg:text-[80px] sm:text-[60px] xs:text-[50px] text-[40px] lg:leading-[98px] mt-2",
	heroSubText:
	  "text-[#dfd9ff] font-medium lg:text-[30px] sm:text-[26px] text-[20px] lg:leading-[40px]",
  
	sectionHeadText:
	  "text-white font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]",
	sectionSubText:
	  "sm:text-[18px] text-[14px] text-secondary uppercase tracking-wider",

	h3:
	  "text-[24px] font-bold text-white",
	h4:
	  "text-[20px] text-white",

	p:
	  "pb-8"
  };
  