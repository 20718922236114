import { motion } from "framer-motion";

import { SectionHeader, SectionWrapper } from "./shared";
import { config, projects, TProject } from "../../models";
import { styles } from "../../styles";
import { fadeIn } from "../../utils/motion";

const Project: React.FC<TProject> = (project) => (
  <>
    <motion.div variants={fadeIn("", "", 0.1, 1)}>
      <h3 className={`mt-10 ${styles.h3}`}>{project.name}</h3>
      {project.description.map((point: string, index: number) => (
        <p
          key={`description-${index}`}
          className={`text-secondary mt-3 text-[17px] leading-[30px]`}
        >
          {point}
        </p>
      ))}

      <h5 className="mt-10">{project.technicals.title}</h5>

      <ul className="ml-5 mt-5 list-disc space-y-2">
        {project.technicals.description.map((point: string, index: number) => (
          <li
            key={`technical-${index}`}
            className="text-secondary pl-1 text-[17px] tracking-wider"
          >
            {point}
          </li>
        ))}
      </ul>
    </motion.div>

    {project.features.map((feature, index) => (
      <div key={`feature-${index}`}>
        <h4 className={`mt-20 ${styles.h4}`}>{feature.title}</h4>
        <ul className="ml-5 mt-5 list-disc space-y-2">
          {feature.description.map((point: string, index: number) => (
            <li
              key={`feature-point-${index}`}
              className="text-secondary pl-1 text-[17px] tracking-wider"
            >
              {point}
            </li>
          ))}
        </ul>
        <div className="mt-10 flex flex-wrap gap-8">
          {feature.videos.map((video: string, index: number) => (
            <video
              key={`video-${feature}-${index}`}
              width="360"
              height="240"
              controls
              loop
              autoPlay
              muted
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ))}
        </div>
      </div>
    ))}
  </>
);

const Projects = () => {
  return (
    <>
      <SectionHeader useMotion={true} {...config.sections.projects} />
      {projects.map((project, index) => (
        <Project key={`project-${index}`} {...project} />
      ))}
    </>
  );
};

export default SectionWrapper(Projects, "projects");
