import { config } from "../../models/config";
import { styles } from "../../styles";

const Hero = () => {
  return (
    <section className={`relative mx-auto h-[500px] w-full top-[120px]`}>
      <div
        className={`inset-0 mx-auto max-w-7xl ${styles.paddingX} flex flex-row items-start gap-5`}
      >
        <div className="mt-5 flex flex-col items-center justify-center">
          <div className="h-5 w-5 rounded-full bg-[#915EFF]" />
          <div className="violet-gradient h-40 w-1 sm:h-80" />
        </div>

        <div>
          <h1 className={styles.heroHeadText}>{config.hero.name}</h1>
          <p className={`${styles.heroSubText} text-white-100 mt-5`}>
            {config.hero.p[0]}
          </p>
          <p className={`${styles.heroSubText} text-white-100 mt-10 italic`}>
            <span className="font-light">{config.hero.p[1]}</span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
