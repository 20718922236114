import { TCommonProps } from "./common";

export type TNavLink = {
	id: string;
  } & Required<Pick<TCommonProps, "title">>;

export const navLinks: TNavLink[] = [
	{
		id: "about",
		title: "About",
	},
	{
		id: "skills",
		title: "Skills",
	},
	{
		id: "experience",
		title: "Experience",
	},
	{
		id: "projects",
		title: "Projects"
	},
	{
		id: "testimonials",
		title: "Testimonials"
	},
	{
		id: "contact",
		title: "Contact",
	}
  ];